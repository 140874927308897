<template>
    <div class="container" style="margin-bottom: 30px;">
        <center>
            <div class="sticky-top">
                <h2 class="my-header">Scott Electric Printable Promotions</h2>
                <h3 class="my-header">Check back often for the latest Promotional Items</h3>
            </div>
        </center>
        <center>
            <img src="../assets/logo.png" style="width: 200px;" />
        </center>
    </div>
</template>

<script>
    export default {
        name: 'Generic'
    }
</script>

<style scoped>
.my-header {
    margin: 30px;
    color: #00ab5a;
}
</style>