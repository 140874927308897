<template>
    <div class="container" style="margin-bottom: 30px;">
        <center>
            <div class="sticky-top">
                <img src="../assets/logo.png" style="width: 200px;" />
                <h2 class="my-header">ST PATRICK'S DAY SHIRTS</h2>
            </div>
        </center>
        <center>

            <div class="card-container" v-show='alerts.length > 0' style="margin-bottom: 30px;"> <!-- Error Alerts -->
                <div class="card border border-success" style="width: 90%;">
                  <div class="card-body">
                    <div v-for='(alert, index) in alerts' :key='alert.msg' :class='alert.type' role="alert">
                      {{ alert.msg }}
                      <button type="btn btn-danger" class="close" data-dismiss="alert" aria-label="Close" @click='closeAlert(index)'>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            <div class="card-container"> <!-- Short Sleeve -->
                <div class="card border border-success" style="width: 90%;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <img src="images/gildan.gif" class="img-fluid" />
                            </div>
                            <div class="col-md-9">
                                <h1 class="card-title green">{{ ss.name }}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 product">
                                <div class="row">
                                    <div class="col">
                                        <img src="images/01.png" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" style="margin-top: 30px;">
                                        <ul>
                                            <li class="green">Based on 24 pcs</li>
                                            <li class="green">Based on 1-color (WHITE) left chest, full back</li>
                                            <li class="green">Mix & Match of styles okay to meet minimum</li>
                                            <li class="green">Brand is subject to change pending stock availability</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 offset-md-1 product-form">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-8" style="text-align: right;">Size</th>
                                            <th class="col-4">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p class="tabletop-pad green">Small (<span class="sm-price">{{ ss.sPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.sQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Medium (<span class="sm-price">{{ ss.mPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.mQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Large (<span class="sm-price">{{ ss.lPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.lQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">XLarge (<span class="sm-price">{{ ss.xLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.xLQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">2XLarge (<span class="sm-price">{{ ss.xxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.xxLQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">3XLarge (<span class="sm-price">{{ ss.xxxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ss.xxxLQty' @keyup='calculateItem("ss")' /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style="margin-top: 30px; text-align: center;" class="green"><strong>Subtotal: </strong>{{ ss.subtotal | currency }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container"> <!-- Long Sleeve -->
                <div class="card border border-success" style="width: 90%;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <img src="images/gildan.gif" class="img-fluid" />
                            </div>
                            <div class="col-md-9">
                                <h1 class="card-title green">{{ ls.name }}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 product">
                                <div class="row">
                                    <div class="col">
                                        <img src="images/02.png" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" style="margin-top: 30px;">
                                        <ul>
                                            <li class="green">Based on 24 pcs</li>
                                            <li class="green">Based on 1-color (WHITE) left chest, full back</li>
                                            <li class="green">Mix & Match of styles okay to meet minimum</li>
                                            <li class="green">Brand is subject to change pending stock availability</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 offset-md-1 product-form">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-8" style="text-align: right;">Size</th>
                                            <th class="col-4">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p class="tabletop-pad green">Small (<span class="sm-price">{{ ls.sPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.sQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Medium (<span class="sm-price">{{ ls.mPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.mQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Large (<span class="sm-price">{{ ls.lPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.lQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">XLarge (<span class="sm-price">{{ ls.xLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.xLQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">2XLarge (<span class="sm-price">{{ ls.xxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.xxLQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">3XLarge (<span class="sm-price">{{ ls.xxxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='ls.xxxLQty' @keyup='calculateItem("ls")' /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style="margin-top: 30px; text-align: center;" class="green"><strong>Subtotal: </strong>{{ ls.subtotal | currency }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container"> <!-- Hoodie -->
                <div class="card border border-success" style="width: 90%;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <img src="images/gildan.gif" class="img-fluid" />
                            </div>
                            <div class="col-md-9">
                                <h1 class="card-title green">{{ hoodie.name }}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 product">
                                <div class="row">
                                    <div class="col">
                                        <img src="images/03.png" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" style="margin-top: 30px;">
                                        <ul>
                                            <li class="green">Based on 24 pcs</li>
                                            <li class="green">Based on 1-color (WHITE) left chest, full back</li>
                                            <li class="green">Mix & Match of styles okay to meet minimum</li>
                                            <li class="green">Brand is subject to change pending stock availability</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 offset-md-1 product-form">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-8" style="text-align: right;">Size</th>
                                            <th class="col-4">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p class="tabletop-pad green">Small (<span class="sm-price">{{ hoodie.sPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.sQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Medium (<span class="sm-price">{{ hoodie.mPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.mQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">Large (<span class="sm-price">{{ hoodie.lPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.lQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">XLarge (<span class="sm-price">{{ hoodie.xLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.xLQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">2XLarge (<span class="sm-price">{{ hoodie.xxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.xxLQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                        <tr>
                                            <td><p class="tabletop-pad green">3XLarge (<span class="sm-price">{{ hoodie.xxxLPrice | currency }}</span>):</p></td>
                                            <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='hoodie.xxxLQty' @keyup='calculateItem("hoodie")' /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style="margin-top: 30px; text-align: center;" class="green"><strong>Subtotal: </strong>{{ hoodie.subtotal | currency }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container"> <!-- Logo -->
                <div class="card border border-success" style="width: 90%">
                  <div class="card-body">
                    <center>
                      <h5 class="card-title">Upload your logo (.pdf or .jpg):</h5>
                    </center>
                    <form class="form-inline card-text">
                      <div class="form-group fg-spacer">
                        <label for="name" class="label-spacer"><strong>Logo: </strong></label>
                        <input id="name" type="file" class="form-control" @change="uploadFile" ref="file" />            
                      </div>
                    </form>
                  </div>
                </div>
            </div>

            <div class="card-container"> <!-- Contact Form -->
                <div class="card border border-success" style="width: 90%">
                  <div class="card-body">
                    <center>
                      <h5 class="card-title">Please complete the following information:</h5>
                      <p class="green">* Required field</p>
                    </center>
                    <form class="form-inline card-text">
                      <div class="form-group fg-spacer">
                        <label for="name" class="label-spacer"><strong>* Your name: </strong></label>
                        <input id="name" type="text" class="form-control" placeholder="Please enter your name" v-model='name' required />            
                      </div>
                      <div class="form-group fg-spacer">
                        <label for="phone" class="label-spacer"><strong>* Your contact phone #: </strong></label>
                        <input id="phone" type="text" class="form-control" placeholder="Please enter your phone #" v-model='phone' required />            
                      </div>
                      <div class="form-group fg-spacer">
                        <label for="email" class="label-spacer"><strong>* E-mail Address: </strong></label>
                        <input id="email" type="email" class="form-control" placeholder="Please enter your e-mail address" v-model='email' required />            
                      </div>
                      <div class="form-group fg-spacer">
                        <label for="acct" class="label-spacer"><strong>Scott Electric Acct # (Optional): </strong></label>
                        <input id="acct" type="text" class="form-control" placeholder="Please enter your Scott Electric Acct #" v-model='acct' />            
                      </div>
                      <div class="form-group fg-spacer">
                        <label for="address1" class="label-spacer"><strong>* Your location address: </strong></label>
                        <input id="address1" type="text" class="form-control" placeholder="Address 1" style="margin-bottom: 10px;" v-model='address1' required /> 
                        <input id="address2" type="text" class="form-control" placeholder="Address 2 (Optional)" style="margin-bottom: 10px;" v-model='address2' />
                        <div class="row">
                          <div class="col-md-5">
                            <input id="city" type="text" class="form-control" placeholder="City" style="margin-bottom: 10px;" v-model='city' required />
                          </div>
                          <div class="col-md-3">
                            <select id="state" class="form-control" style="margin-bottom: 10px;" v-model='state'>
                              <option value="">Select a state</option>
                              <option v-for='state in states' :key='state.abbreviation' :value='state.abbreviation'>{{ state.abbreviation }}</option>  
                            </select>
                          </div>
                          <div class="col-md-4">
                            <input id="zip" type="text" class="form-control" placeholder="Zip code" v-model='zip' required /> 
                          </div>
                        </div>          
                      </div>
                    </form>
                  </div>
                </div>
            </div>

            <div class="card-container">
                <div class="card border border-success" style="width: 90%">
                    <div class="card-body">
                        <center>
                            <button @click='submit' :disabled="isActive" class="btn btn-success">Submit</button>
                        </center>
                    </div>
                </div>
            </div>
        </center>
    </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */
 import States from '@/services/States.service.js';
 import axios from 'axios'
 
 export default {
     name: 'Paddys22',
     data() {
         return {
             alerts: [],
             isActive: false,
             states: States.states,
             logo: null,
             name: null,
             phone: null,
             email: null,
             acct: null,
             address1: null,
             address2: null,
             city: null,
             state: '',
             zip: null,
             grandTotal: 0,
             ss: {
                name: 'GILDAN - 5000 S/S Tees (Color: Irish Green)',
                sPrice: 5.50,
                sQty: null,
                mPrice: 5.50,
                mQty: null,
                lPrice: 5.50,
                lQty: null,
                xLPrice: 5.50,
                xLQty: null,
                xxLPrice: 7.50,
                xxLQty: null,
                xxxLPrice: 9.50,
                xxxLQty: null,
                subtotal: 0
            },
            ls: {
                name: 'GILDAN - 5400 L/S Tees (Color: Irish Green)',
                sPrice: 8.25,
                sQty: null,
                mPrice: 8.25,
                mQty: null,
                lPrice: 8.25,
                lQty: null,
                xLPrice: 8.25,
                xLQty: null,
                xxLPrice: 10.25,
                xxLQty: null,
                xxxLPrice: 12.25,
                xxxLQty: null,
                subtotal: 0
             },
            hoodie: {
                name: 'GILDAN - 18500 Pullover Hoodies (Color: Irish Green)',
                sPrice: 15.00,
                sQty: null,
                mPrice: 15.00,
                mQty: null,
                lPrice: 15.00,
                lQty: null,
                xLPrice: 15.00,
                xLQty: null,
                xxLPrice: 17.00,
                xxLQty: null,
                xxxLPrice: 19.00,
                xxxLQty: null,
                subtotal: 0
             }
         }
     },
     filters: {
        currency: function (value) {
        value = value.toFixed(2)
        value+=''
        var x = value.split('.')
        var x1 = x[0]
        var x2 = x.length > 1 ? '.' + x[1] : ''
        var rgx = /(\d+)(\d{3})/
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2')
        }
        var val = x1 + x2
        return '$' + val
        }  
    },
    methods: {
        closeAlert (index) {
            this.alerts.splice(index, 1)
        },
        convertToNumber (item) {
            if (!item || item === '') {
                return 0
            } else {
                return parseInt(item)
            }
        },
        submit () {
            if (!this.name || this.name === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid name.'})
                return window.scrollTo(0,0);
            }
            if (!this.phone || this.phone === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid phone number.'})
                return window.scrollTo(0,0);
            }
            if (!this.email || this.email === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid e-mail address.'})
                return window.scrollTo(0,0);
            }
            if (!this.address1 || this.address1 === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid address.'})
                return window.scrollTo(0,0);
            }
            if (!this.city || this.city === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid city.'})
                return window.scrollTo(0,0);
            }
            if (!this.state || this.state === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid state.'})
                return window.scrollTo(0,0);
            }
            if (!this.zip || this.zip === '') {
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Please enter a valid zip code.'})
                return window.scrollTo(0,0);
            }
            this.isActive = true;
            const formData = new FormData();
            formData.append('file', this.logo);
            formData.append('name', this.name);
            formData.append('phone', this.phone);
            formData.append('email', this.email);
            formData.append('acct', this.acct);
            formData.append('address1', this.address1);
            formData.append('address2', this.address2);
            formData.append('city', this.city);
            formData.append('state', this.state);
            formData.append('zip', this.zip);
            formData.append('grandTotal', this.grandTotal);
            formData.append('ss', this.ss.name);
            formData.append('ss', this.ss.sPrice);
            formData.append('ss', this.convertToNumber(this.ss.sQty));
            formData.append('ss', this.ss.mPrice);
            formData.append('ss', this.convertToNumber(this.ss.mQty));
            formData.append('ss', this.ss.lPrice);
            formData.append('ss', this.convertToNumber(this.ss.lQty));
            formData.append('ss', this.ss.xLPrice);
            formData.append('ss', this.convertToNumber(this.ss.xLQty));
            formData.append('ss', this.ss.xxLPrice);
            formData.append('ss', this.convertToNumber(this.ss.xxLQty));
            formData.append('ss', this.ss.xxxLPrice);
            formData.append('ss', this.convertToNumber(this.ss.xxxLQty));
            formData.append('ss', this.ss.subtotal);
            formData.append('ls', this.ls.name);
            formData.append('ls', this.ls.sPrice);
            formData.append('ls', this.convertToNumber(this.ls.sQty));
            formData.append('ls', this.ls.mPrice);
            formData.append('ls', this.convertToNumber(this.ls.mQty));
            formData.append('ls', this.ls.lPrice);
            formData.append('ls', this.convertToNumber(this.ls.lQty));
            formData.append('ls', this.ls.xLPrice);
            formData.append('ls', this.convertToNumber(this.ls.xLQty));
            formData.append('ls', this.ls.xxLPrice);
            formData.append('ls', this.convertToNumber(this.ls.xxLQty));
            formData.append('ls', this.ls.xxxLPrice);
            formData.append('ls', this.convertToNumber(this.ls.xxxLQty));
            formData.append('ls', this.ls.subtotal);
            formData.append('hoodie', this.hoodie.name);
            formData.append('hoodie', this.hoodie.sPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.sQty));
            formData.append('hoodie', this.hoodie.mPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.mQty));
            formData.append('hoodie', this.hoodie.lPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.lQty));
            formData.append('hoodie', this.hoodie.xLPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.xLQty));
            formData.append('hoodie', this.hoodie.xxLPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.xxLQty));
            formData.append('hoodie', this.hoodie.xxxLPrice);
            formData.append('hoodie', this.convertToNumber(this.hoodie.xxxLQty));
            formData.append('hoodie', this.hoodie.subtotal);

            const headers = { 'Content-Type': 'undefined' };
            axios.post('https://printpromo.scottelectricusa.com/api/submit', formData, { headers }).then((res) => {
            //axios.post('http://localhost:8082/api/submit', formData, { headers }).then((res) => {
                this.isActive = false;
                if (res.data.success === true) {
                    this.$router.push('/thank-you');
                } else {
                    this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'There was a problem and your order could not be submitted.  Error: ' + JSON.stringify(res.data.err) });
                    return window.scrollTo(0,0); 
                }
            })
            .catch((err) => {
                this.isActive = false;
                this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: JSON.stringify(err) })
                return window.scrollTo(0,0);
            });            
        },
        uploadFile () {
            this.logo = this.$refs.file.files[0];
        },
        calculateGrandTotal () {
            this.grandTotal = 
                this.ss.subtotal+
                this.ls.subtotal+
                this.hoodie.subtotal;
        },
        calculateItem (itemIndex) {
            switch (itemIndex) {
                case 'ss':
                    this.ss.subtotal = 
                        (this.ss.sPrice*this.ss.sQty)+
                        (this.ss.mPrice*this.ss.mQty)+
                        (this.ss.lPrice*this.ss.lQty)+
                        (this.ss.xLPrice*this.ss.xLQty)+
                        (this.ss.xxLPrice*this.ss.xxLQty)+
                        (this.ss.xxxLPrice*this.ss.xxxLQty);
                        break;
                case 'ls':
                    this.ls.subtotal = 
                        (this.ls.sPrice*this.ls.sQty)+
                        (this.ls.mPrice*this.ls.mQty)+
                        (this.ls.lPrice*this.ls.lQty)+
                        (this.ls.xLPrice*this.ls.xLQty)+
                        (this.ls.xxLPrice*this.ls.xxLQty)+
                        (this.ls.xxxLPrice*this.ls.xxxLQty);
                        break;
                case 'hoodie':
                    this.hoodie.subtotal = 
                        (this.hoodie.sPrice*this.hoodie.sQty)+
                        (this.hoodie.mPrice*this.hoodie.mQty)+
                        (this.hoodie.lPrice*this.hoodie.lQty)+
                        (this.hoodie.xLPrice*this.hoodie.xLQty)+
                        (this.hoodie.xxLPrice*this.hoodie.xxLQty)+
                        (this.hoodie.xxxLPrice*this.hoodie.xxxLQty);
                        break;
            }
            this.calculateGrandTotal();
        }
    }
 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.green {
    color: #033021;
}
.label-spacer {
  margin-bottom: 3px;
}
.fg-spacer {
  margin-bottom: 15px;
}
.price {
  font-size: 1.16em;
}
.tabletop-pad {
  padding-top: 8px;
  text-align: right;
}
.my-header {
    font-size: 4em;
    margin: 30px;
    color: #00ab5a;
}
.card-container {
    margin-bottom: 30px;
}
</style>