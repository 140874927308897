<template>
  <Generic />
  <!--<Paddys22 />-->
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 

import Paddys22 from '@/components/Paddys22.vue'
import Generic from '@/components/Generic.vue'

export default {
  name: 'Home',
  components: {
    Paddys22,
    Generic
  }
}
</script>

